<template>
  <div class="school-packet-book mt-3 mx-3">
    <div>
      <v-progress-circular
        v-if="!instance"
        color="primary"
        class="mx-3 mb-3"
        :size="40"
        indeterminate
      ></v-progress-circular>
      <div
        v-else
        class="school-packet-header d-flex justify-content-between px-0"
      >
        <div
          class="d-flex justify-content-between col-6"
        >
          <ul>
            <li>ID Instansi</li>
            <li>Nama Instansi </li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: {{ instance.identity }}</span></li>
            <li><span class="font-weight-bold">: {{ instance.name }}</span></li>
          </ul>
        </div>
        <div class="d-flex justify-content-between col-6">
          <ul>
            <li>Tanggal Bergabung</li>
            <li>Tipe Instansi</li>
          </ul>
          <ul>
            <li><span class="font-weight-bold">: {{ dateFormat(instance.user.created_at) }}</span></li>
            <li><span class="font-weight-bold">: {{ instance.company_types_uuid.type }}</span></li>
          </ul>
        </div>
      </div>
    </div>

    <v-divider></v-divider>
    <div class="school-packet-title d-flex align-center justify-space-between px-0 py-4">
      <v-col
        cols="5"
        class="px-0"
      >
        <h2>Daftar Riwayat Pembelian</h2>
        <p class="text-body-2 mt-1">
          Daftar buku ditampilkan pertanggal pembelian
        </p>
      </v-col>
    </div>
    <div class="d-flex align-center justify-space-between px-0 mb-0">
      <v-col
        cols="5"
        class="px-0"
      >
        <v-card>
          <v-card-title>
            Tanggal Pembelian
          </v-card-title>
          <!-- // eslint-disable-next-line vue/valid-v-for -->
          <v-list-item
            class="mb-3 "
          >
            <v-list-item-content>
              <v-list-item-title>
                <a
                  class="d-flex justify-space-between align-center"
                  style="cursor: default;"
                >
                  <span
                    class="black--text"
                  >
                    Seluruh Pembelian <br />
                  </span>
                  <v-chip
                    color="primary"
                  >
                    <span class="white--text">{{ total }} Buku</span>
                  </v-chip>
                </a>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list
            height="400"
            class="overflow-auto"
          >
            <div
              v-if="isLoadingPackage && !packages.length"
              class="d-flex justify-center mt-5"
            >
              <v-progress-circular
                color="primary"
                class="mt-5"
                :size="50"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-list-item
              v-for="pack in packages"
              :key="pack.uuid"
              :class="package_uuid === pack.uuid ? 'card-active': ''"
              @click="getTransaction(pack.uuid) "
            >
              <v-list-item-content>
                <v-list-item-title>
                  <a
                    class="d-flex justify-space-between align-center"
                  >
                    <span
                      :class="package_uuid === pack.uuid ? 'white--text' : 'black--text'"
                    >
                      {{ dateIndo(pack.created_at) }} <br />
                      <small :class="package_uuid === pack.uuid ? 'white--text' : 'black--text'">{{ pack.package }}</small>
                    </span>
                    <v-chip
                      :color="package_uuid === pack.uuid ? 'white' : 'primary'"
                    >
                      <span :class="package_uuid === pack.uuid ? 'primary--text' : 'white--text'">{{ pack.item_count }} Buku</span>
                    </v-chip>
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="7"
        class="pr-0"
      >
        <v-card>
          <v-card-title>List Buku</v-card-title>
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            label="Cari Buku"
            single-line
            hide-details
            dense
            outlined
            class="mx-5 mb-5"
          ></v-text-field>
          <!-- <v-divider></v-divider> -->
          <v-simple-table
            height="400"
            class="overflow-auto"
          >
            <template v-slot:default>
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Jumlah
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="isLoadingLibrary"
                  style="display: contents"
                >
                  <td
                    colspan="3"
                    style="border: none"
                  >
                    <div
                      class="d-block text-center mx-auto mt-5"
                    >
                      <v-progress-circular
                        class="mt-5"
                        color="primary"
                        :size="50"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="(lib,index) in libraries"
                  :key="lib.uuid"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ lib.name }}</td>
                  <td></td>
                </tr>
                <infinite-loading
                  v-if="!package_uuid"
                  :identifier="infiniteId"
                  @distance="1"
                  @infinite="infiniteHandler"
                >
                  <div slot="no-more">
                  </div>
                  <div slot="no-results">
                  </div>
                </infinite-loading>
                <infinite-loading
                  v-else
                  :identifier="infiniteId"
                  @distance="1"
                  @infinite="infiniteHandlerPackage"
                >
                  <div slot="no-more">
                  </div>
                  <div slot="no-results">
                  </div>
                </infinite-loading>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </div>

    <!-- <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Paket"
      @close-button="confirmDialog = false"
      @destroy-button="destroyPacket"
    >
      <template v-slot:body>
        Apakah anda yakin ingin menghapus paket "" ?
      </template>
    </ConfirmDialog> -->
  </div>
</template>

<script>
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import {
  mdiBook, mdiBookMusic, mdiFileVideo, mdiMagnify, mdiMapSearch,
} from '@mdi/js'

export default {
  name: 'InstanceHistory',
  components: {
    // InfiniteLoading,
  },
  props: {
    instanceUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingLibrary: false,
      confirmDialog: false,
      searchDebounce: '',
      categories: [],
      libraries: [],
      notices: [
        { title: 'Buku yang akan dibeli', isChecked: true },
        { title: 'Buku yang sudah dibeli', isChecked: true },
        { title: 'Buku yang tidak dibeli', isChecked: false },
      ],
      icons: {
        mdiMagnify,
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
        mdiMapSearch,
      },
      packet: [],
      packets: [],
      package_uuid: '',
      packetDetail: {},
      isLoadingButton: false,
      isLoadingPackage: false,
      isDisabledButton: false,
      timeout: null,
      totalBook: 0,
      cardActive: false,
      isEmpty: false,
      servicePackage: 'package',
      serviceInstance: 'instance',
      serviceTransaction: 'transaction',
      serviceLibrary: 'library',
      packages: [],
      pkg: null,
      packageLibrary: [],
      instance: null,
      pageLib: 1,
      pageLibPackage: 1,
      isInfiniteAll: true,
      isInfiniteCategory: false,
      infiniteId: +new Date(),
    }
  },
  computed: {
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.searchDebounce = val
            this.pageLib = 1
            this.pageLibPackage = 1
            this.listLibrary({ library: this.searchDebounce })
            this.refreshInfiniteLoading()
          }, 500)
        }
      },
    },
  },
  watch: {
    instanceUuid: {
      handler() {
        if (this.instanceUuid) {
          this.resetState()
          this.loadReset()
          this.getInstance(this.instanceUuid)
        }
      },
    },
  },
  created() {
    this.getInstance(this.instanceUuid)
  },
  mounted() {},
  methods: {
    refreshInfiniteLoading() {
      if (this.pageLib === 1 || this.pageLibPackage === 1) {
        this.infiniteId += 1
      }
    },

    async infiniteHandler($state) {
      this.pageLib += 1
      console.log('oke 1')
      await this.$services.ApiServices.get(this.serviceTransaction, this.package_uuid, {
        library: this.searchDebounce,
        page: this.pageLib,
        per_page: 100,
      }).then(({ data }) => {
        if (data.data.length) {
          this.libraries = [...this.libraries, ...data.data.library]
          this.libraries = this.libraries.map(item => ({
            ...item,
          }))
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },

    async infiniteHandlerPackage($state) {
      this.pageLibCategory += 1
      console.log('oke 2')
      await this.$services.ApiServices.get(this.serviceTransaction, this.package_uuid, {
        library: this.searchDebounce,
        page: this.pageLibPackage,
        per_page: 100,
      }).then(({ data }) => {
        if (data.data.length) {
          this.libraries = [...this.libraries, ...data.data.library]
          this.libraries = this.libraries.map(item => ({
            ...item,
          }))
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },

    async listLibrary(params = {}) {
      this.isLoadingLibrary = true
      await this.$services.ApiServices.list(this.serviceLibrary, {
        ...params,
        per_page: 100,
        page: 1,
      }).then(
        ({ data }) => {
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          // this.totalItemsLib = data.meta.total
          // this.totalPagesLib = data.meta.per_page
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },

    async getInstance() {
      this.isLoadingPackage = true

      await this.$services.ApiServices.get(this.serviceInstance, this.instanceUuid).then(
        ({ data }) => {
          this.instance = data.data
          this.packages = data.data.transaction_history
          this.isLoadingPackage = false
          // console.log(this.packages)
          const countData = data.data.transaction_history
          let count = 0
          countData.forEach(item => {
            count += parseFloat(item.item_count, 10)
          })
          this.total = count
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingPackage = false
    },
    async getTransaction(uuid) {
      this.libraries = []
      this.package_uuid = uuid
      this.isLoadingLibrary = true
      this.refreshInfiniteLoading()
      await this.$services.ApiServices.get(this.serviceTransaction, uuid, { per_page: 100, page: 1 }).then(
        ({ data }) => {
          this.libraries = data.data.library.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    dateFormat(val) {
      return dateTimeFormat(val, '/', true, false)
    },
    loadReset() {
      this.instance = null
      this.packageLibrary = []
      this.package_uuid = null
    },
    dateIndo(val) {
      return dateTimeFormat(val, ' ', false, true)
    },
  },
}
</script>

<style scoped>
.school-packet-header .header-left ul {
  margin-left: 0;
  /* border: 1px solid #000; */
}
.school-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.card-active {
  /* outline: 1px solid #AC268F !important; */
  background: #AC268F !important;
}

.text-color {
  color: #AC268F;
}

.v-application a {
  color: #746d7d;
  text-decoration: none;
}
</style>
