<template>
  <div>
    <div class="px-4">
      <div class="d-flex align-center justify-space-between">
        <v-col
          cols="6"
          class="pl-0"
        >
          <h2>Pilih Paket Buku Baru (Optional)</h2>
          <p>Cari dan pilih buku sesuai kebutuhan paket</p>
        </v-col>

        <v-col
          cols="6"
          class="pr-0"
        >
          <v-autocomplete
            v-model="packageOption"
            label="Pilih Paket"
            :items="packages"
            :disabled="!packages.length || packageDetail"
            :loading="!packages.length"
            item-text="name"
            item-value="uuid"
            dense
            outlined
            clearable
            @change="getByPacket($event)"
          ></v-autocomplete>
        </v-col>
        <div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="my-6 align-center">
        <h2>Semua Paket</h2>
        <p>Daftar paket buku</p>
        <div class="text-center">
          <v-progress-circular
            v-if="!packages.length"
            color="primary"
            class="mx-3 mb-3"
            :size="40"
            indeterminate
          ></v-progress-circular>
        </div>
        <div>
          <div class="align-center scroll-menu">
            <div
              v-for="pack in packages"
              :key="pack.uuid"

              class="demo-space-x"
            >
              <v-card
                max-height="100"
                min-width="150"
                :class="cardActive == pack.uuid ? 'card-active' : false"
                :disabled="packageOption"
                @click="handlerPacketOption(pack.uuid, $event)"
              >
                <v-card-text class="align-center">
                  <p class="font-weight-bold mb-2 primary--text">
                    {{ pack.name }}
                  </p>
                  <span class="font-weight-bold mb-2">
                    Rp.  {{ pack.price }}
                  </span>
                  <!-- <span class="font-weight-bold mb-2">
                  Total Buku {{ pack.library.length }}
                </span> -->
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div
        class="my-4"
      >
        <h5 class="mb-4">
          Informasi Paket
        </h5>
        <div class="text-center">
          <v-progress-circular
            v-if="!packageDetail && loader"
            color="primary"
            class="mx-3 mb-3"
            :size="40"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-row v-if="packageDetail">
          <v-col
            md="5"
            class="mx-3"
          >
            <div class="d-inline-block">
              <div class="grey lighten-1">
                Nama Paket
              </div>
              <div class="grey lighten-1">
                Deskripsi
              </div>
            </div>
            <div class="d-inline-block ml-4">
              <div class="black--text">
                : {{ packageDetail.name }}
              </div>
              <div class="black--text">
                : {{ packageDetail.description }}
              </div>
            </div>
          </v-col>
          <v-col
            md="5"
          >
            <div class="d-inline-block">
              <div>Harga</div>
              <div>Jumlah Buku</div>
            </div>
            <div class="d-inline-block ml-4">
              <div
                class="black--text"
              >
                : <span v-if="packageDetail.price">Rp {{ packageDetail.price }}</span>
              </div>
              <div
                class="black--text"
              >
                : <span>{{ packageDetail.library.length }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="packageDetail && packages.length > 0">
        <v-btn
          color="primary"
          outlined
          small
          class="mx-2 mb-6"
          @click="destroyOption(packageDetail.uuid)"
        >
          Hapus Pilihan
        </v-btn>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="d-flex align-center justify-space-between px-4">
      <v-col
        cols="6"
        class="pl-0"
      >
        <h2>Pilih Buku</h2>
        <p>Cari dan pilih buku sesuai kebutuhan paket</p>
        <div class="ml-4 d-flex align-center">
          Select All By Category : <v-switch
            v-model="isSelectAll"
            class="ml-4"
            @change="checkButton"
          ></v-switch>
        </div>
      </v-col>
      <v-col
        cols="6"
        class="pr-0"
      >
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Cari Buku"
          single-line
          hide-details
          dense
          outlined
          class=""
        ></v-text-field>
      </v-col>
    </div>
    <v-row class="px-5">
      <v-col
        cols="5"
        class="pl-0"
      >
        <v-card>
          <v-card-title>
            Kategori
          </v-card-title>
          <v-divider></v-divider>
          <v-list
            height="400"
            class="overflow-auto"
          >
            <v-list-item class="active">
              <v-list-item-content>
                <v-list-item-title>
                  <a
                    href=""
                    @click.prevent="clickAll"
                  >Semua Kategori</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div class="text-center">
              <v-progress-circular
                v-if="!categories.length"
                color="primary"
                class="px-10 my-10"
                :size="45"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-list-item class="pl-0">
              <v-list-item-content>
                <v-list-item-title>
                  <v-treeview
                    activatable
                    rounded
                    hoverable
                    color="primary"
                    :items="categories"
                    item-key="uuid"
                    item-text="name"
                    item-children="sub_categories"
                  >
                    <template
                      slot="label"
                      slot-scope="{ item }"
                    >
                      <a
                        class="d-flex justify-space-between align-center"
                        @click.prevent="changeLibraryList(item.uuid)"
                      >
                        <span
                          class="text-truncate"
                          style="max-width: 150px"
                        >{{ item.name }}</span>
                        <v-chip
                          color="primary"
                        >
                          {{ item.total_libraries }} Buku
                        </v-chip>
                      </a>
                    </template>
                  </v-treeview>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card class="my-8">
          <v-card-text>
            <h4>Total Seluruh Buku : {{ total_book }}</h4>
            <p>Total buku yang sudah dibeli dan yang akan dibeli</p>
          <!-- <h4>Total E-Book : {{ totalBook }}</h4>
              <h4>Total Video  : {{ totalVideo }}</h4>
              <h4>Total Audio  : {{ totalAudio }}</h4> -->
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="7"
        class="pr-0"
      >
        <v-card>
          <v-card-title>Pilih Buku</v-card-title>
          <v-divider></v-divider>
          <v-simple-table
            height="400"
            class="overflow-auto"
          >
            <template v-slot:default>
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    Type Library
                  </th>
                  <th class="text-left">
                    Nama Buku
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="isLoadingLibrary"
                  style="display: contents"
                >
                  <td
                    colspan="3"
                    style="border: none"
                  >
                    <div
                      class="d-block text-center mx-auto mt-5"
                    >
                      <v-progress-circular
                        class="mt-5"
                        color="primary"
                        :size="50"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="(library, index) in libraries"
                  v-else
                  :key="index"
                >
                  <td v-if="packet_school_uuid === null">
                    <v-checkbox
                      v-model="librariesInstance"
                      :value="library.uuid"
                      @change="handleCheckboxChange(library.uuid, librariesInstance)"
                    >
                    </v-checkbox>
                  </td>
                  <td v-else>
                    <v-checkbox
                      v-model="librariesInstance"
                      disabled
                      :value="library.uuid"
                      @change="handleCheckboxChange(library.uuid, librariesInstance)"
                    >
                    </v-checkbox>
                  </td>
                  <td>
                    <!-- <div class="d-flex justify-content-center">
                      <v-chip
                        v-for="(itemCL,key) in library.typeLibrary"
                        :key="key"
                        outlined
                        :class="getBadgeType(itemCL)"
                        h-e-a-d
                      >
                        <v-avatar left>
                          <v-icon>{{ getIconType(key) }}</v-icon>
                        </v-avatar>
                        {{ getTextType(itemCL) }}
                      </v-chip>
                    </div> -->
                    <div class="d-flex justify-content-center">
                      <v-chip
                        outlined
                        :class="`${getBadgeType(library.type)} text-capitalize`"
                      >
                        {{ library.type }}
                      </v-chip>
                    </div>
                  </td>
                  <td>{{ library.name }}</td>
                </tr>
                <infinite-loading
                  v-if="!categoryUuid"
                  :identifier="infiniteId"
                  @distance="1"
                  @infinite="infiniteHandler"
                >
                  <div slot="no-more">
                  </div>
                  <div slot="no-results">
                  </div>
                </infinite-loading>
                <infinite-loading
                  v-else
                  :identifier="infiniteId"
                  @distance="1"
                  @infinite="infiniteHandlerCategory"
                >
                  <div slot="no-more">
                  </div>
                  <div slot="no-results">
                  </div>
                </infinite-loading>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <div class="notice my-8">
          <h3>Keterangan :</h3>
          <div class="d-flex justify-space-between">
            <div
              v-for="notice in notices"
              :key="notice.title"
            >
              <div
                v-if="notice.disabled === false"
                class="d-flex align-center"
              >
                <v-checkbox
                  v-model="notice.isChecked"
                  readonly
                  class="active-checkbox"
                ></v-checkbox>
                <small>{{ notice.title }}</small>
              </div>
              <div
                v-else
                class="d-flex align-center"
              >
                <v-checkbox
                  v-model="notice.isChecked"
                  disabled
                ></v-checkbox>
                <small>{{ notice.title }}</small>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-center mb-1 mt-7">
      <v-btn
        color="primary"
        large
        class="mx-2"
        :loading="isLoadingButton"
        :disabled="isLoadingButton"
        @click="updatePackage"
      >
        Simpan
      </v-btn>
      <v-btn
        color="primary"
        outlined
        class="mx-2"
        large
        @click="$emit('handle-close', false)"
      >
        Batal
      </v-btn>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// eslint-disable-next-line object-curly-newline
import { mdiBook, mdiBookMusic, mdiFileVideo, mdiMagnify, mdiMapSearch } from '@mdi/js'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'SchoolSuperAdmin',
  components: {
    // DataTablePagination,
    // SchoolForm,
    // SchoolPacketBook,
    // SchoolFeature,
    // ConfirmDialog,
    InfiniteLoading,
  },
  props: {
    instanceUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSelectAll: false,
      packet_school_uuid: null,
      searchDebounce: '',
      libraries: [],
      librariesInstance: [],
      libraryOld: [],
      categories: [],
      packages: [],
      notices: [
        { title: 'Buku yang akan dibeli', isChecked: true, disabled: false },
        { title: 'Buku yang sudah dibeli', isChecked: true, disabled: true },
        { title: 'Buku yang tidak dibeli', isChecked: false, disabled: true },
      ],
      icons: {
        mdiMagnify,
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
        mdiMapSearch,
      },
      total_book: 0,
      packageOption: null,
      packageDetail: null,
      servicePackage: 'package',
      servieCategory: 'category',
      serviceLibraryCategory: 'categorylibrary',
      serviceLibraryInstance: 'instancelibrary',
      serviceLibrary: 'library',
      serviceTransaction: 'transaction',
      isLoadingPackage: false,
      isLoadingLibrary: false,
      isLoadingButton: false,
      loader: false,
      cardActive: false,
      pageLib: 1,
      pageLibCategory: 1,
      isInfiniteAll: true,
      isInfiniteCategory: false,
      categoryUuid: null,
      infiniteId: +new Date(),
    }
  },
  computed: {
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.searchDebounce = val
            this.pageLib = 1
            this.pageLibCategory = 1
            this.listLibrary({ library: this.searchDebounce })
            this.refreshInfiniteLoading()
          }, 500)
        }
      },
    },
  },
  watch: {
    instanceUuid: {
      handler() {
        if (this.instanceUuid) {
          this.librariesInstance = []
          this.resetState()
          this.getInstanceLibrary(this.instanceUuid)
          this.listLibrary()
        }
      },
    },
  },
  created() {
    this.getInstanceLibrary(this.instanceUuid)
    this.listLibrary()
  },
  mounted() {
    this.listCategory()
    this.listPackage()
  },
  methods: {
    refreshInfiniteLoading() {
      if (this.pageLib === 1 || this.pageLibCategory === 1) {
        this.infiniteId += 1
      }
    },

    async infiniteHandler($state) {
      this.pageLib += 1
      console.log('oke 1')

      await this.$services.ApiServices.list(this.serviceLibrary, {
        library: this.searchDebounce,
        page: this.pageLib,
        per_page: 100,
      }).then(({ data }) => {
        if (data.data.length) {
          this.libraries = [...this.libraries, ...data.data]
          this.libraries = this.libraries.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    async infiniteHandlerCategory($state) {
      this.pageLibCategory += 1
      console.log('oke 2')

      await this.$services.ApiServices.get(this.serviceLibraryCategory, this.categoryUuid, {
        library: this.searchDebounce,
        page: this.pageLibCategory,
        per_page: 100,
      }).then(({ data }) => {
        if (data.data.length) {
          this.libraries = [...this.libraries, ...data.data]
          this.libraries = this.libraries.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    },
    async updatePackage() {
      this.isLoadingButton = true
      const libraryNew = this.librariesInstance.filter(person => !this.libraryOld.includes(person))
      this.formData = new FormData()
      libraryNew.forEach(el => {
        this.formData.append('library_uuid[]', el)
      })
      if (this.packet_school_uuid !== null) {
        this.formData.append('package_uuid', this.packet_school_uuid)
        this.formData.delete('library_uuid[]')
      }
      this.formData.append('price', 0)
      this.formData.append('company_uuid', this.instanceUuid)
      if (libraryNew.length < 1 && this.packet_school_uuid === null) {
        this.showSnackbar({
          text: 'Cannot Remove your current library!!, please add one',
          color: 'error',
        })
        this.isLoadingButton = false

        return
      }
      await this.$services.ApiServices.add(this.serviceTransaction, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Order Library Has Sucessfully!!',
            color: 'success',
          })
          this.isLoadingButton = false
          this.secondOrderDialog = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.$emit('handle-close', true)
    },
    async listLibrary(params = {}) {
      this.isLoadingLibrary = true
      await this.$services.ApiServices.list(this.serviceLibrary, {
        ...params,
        per_page: 100,
        page: 1,
      }).then(
        ({ data }) => {
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.totalItemsLib = data.meta.total
          this.totalPagesLib = data.meta.per_page
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    async getInstanceLibrary(uuid) {
      await this.$services.ApiServices.get(this.serviceLibraryInstance, uuid, { per_page: 'all' }).then(
        async ({ data }) => {
          const librariesData = data.data

          librariesData.forEach(item => {
            item.selected = this.librariesInstance.includes(item.uuid)
            if (!item.selected) {
              this.librariesInstance.push(item.uuid)
              this.libraryOld.push(item.uuid)
            }
          })
          this.total_book = this.librariesInstance.length
          // this.totalBook = data.total_ebook
          // this.totalAudio = data.total_audio_book
          // this.totalVideo = data.total_video_book
        },
        err => console.error(err),
      )
    },
    async listPackage(params = { response_type: 'names' }) {
      this.isLoadingPackage = true
      await this.$services.ApiServices.list(this.servicePackage, {
        ...params,
        per_page: 'all',
      }).then(
        ({ data }) => {
          this.packages = data.data
          this.isLoadingPackage = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingPackage = false
    },
    async getByPacket(uuid) {
      this.isLoadingLibrary = true
      this.packageOption = uuid
      this.resetState()
      this.packet_school_uuid = null
      await this.$services.ApiServices.get(this.servicePackage, uuid).then(
        async ({ data }) => {
          const librariesData = data.data.library

          librariesData.forEach(item => {
            item.selected = this.librariesInstance.includes(item.uuid)
            if (!item.selected && this.isSelectAll) this.librariesInstance.push(item.uuid)
          })
          this.libraries = data.data.library.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.total_book = this.librariesInstance.length
          this.isLoadingLibrary = false
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    async changeLibraryList(uuid) {
      this.packet_school_uuid = null
      this.categoryUuid = uuid
      this.isLoadingLibrary = true
      this.refreshInfiniteLoading()
      await this.$services.ApiServices.get(this.serviceLibraryCategory, uuid, { per_page: 100, page: 1 }).then(
        ({ data }) => {
          const librariesData = data.data

          librariesData.forEach(item => {
            item.selected = this.librariesInstance.includes(item.uuid)
            if (!item.selected && this.isSelectAll) this.librariesInstance.push(item.uuid)
          })
          this.libraries = data.data.map(item => ({
            ...item,
            typeLibrary: {
              audio: item.audio,
              video: item.video,
              ebook: item.ebook,
            },
          }))
          this.total_book = this.librariesInstance.length
        },
        err => console.error(err),
      )
      this.isLoadingLibrary = false
    },
    async listCategory() {
      await this.$services.ApiServices.list(this.servieCategory, { per_page: 'all' }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },
    async handlerPacketOption(uuid) {
      this.loader = true
      this.packageDetail = null
      this.cardActive = uuid
      await this.$services.ApiServices.get(this.servicePackage, uuid).then(
        ({ data }) => {
          this.packageDetail = data.data
          this.packet_school_uuid = this.packageDetail.uuid
        },
        err => console.error(err),
      )
    },
    handleCheckboxChange() {
      console.log(this.librariesVillage.length)
    },
    async clickAll() {
      this.pageLib = 1
      this.pageLibCategory = 1
      this.categoryUuid = null
      this.refreshInfiniteLoading()
      await this.listLibrary()
    },
    checkButton() {
      if (!this.isSelectAll) {
        this.librariesInstance = []
        this.total_book = this.librariesInstance.length
      }
    },
    destroyOption(uuid) {
      if (uuid !== null) {
        this.resetState()
      }
    },
    getBadgeType(type) {
      if (type === 'ebook') {
        return 'v-chip-light-bg warning--text'
      }
      if (type === 'audio') {
        return 'v-chip-light-bg success--text'
      }

      return 'v-chip-light-bg primary--text'
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    resetState() {
      this.packet_school_uuid = null
      this.total_book = 0
      this.cardActive = false
      this.packageDetail = null
      this.loader = false
    },
  },
}
</script>

<style scoped>
.scroll-menu {
  position: relative;
  z-index: 3;
  overflow-y: hidden;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  overflow-x: hidden;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.scroll-menu:hover {
  overflow-x: auto;
}
.demo-space-x {
  height: auto !important;
  padding: 5px 5px !important;
  border-radius: 24px !important;
}
.school-packet-header .header-left ul {
  margin-left: 0;
  /* border: 1px solid #000; */
}
.school-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.card-active {
  border: 2px solid #AC268F !important;
  background: rgba(176, 191, 181, 0.05) !important;
}
.v-application a {
  color: #746d7d;
  text-decoration: none;
}
.active-checkbox.v-input::v-deep
  > .v-input__control
  > .v-input__slot
  > .v-input--selection-controls__input
  > .v-input--selection-controls__ripple {
  cursor: default;
}
.active-checkbox.v-input::v-deep
  > .v-input__control
  > .v-input__slot
  > .v-input--selection-controls__input
  > .v-input--selection-controls__ripple:hover {
  cursor: default;
  height: 0 !important;
  border-radius: 0 !important;
}
</style>
