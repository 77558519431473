<template>
  <v-card id="instance-card">
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="(tabItem,index) in tabs"
        :key="tabItem.title"
        @click="reRenderComponent(index)"
      >
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <instance-add
          :key="componentKey"
        ></instance-add>
      </v-tab-item>
      <v-tab-item>
        <instance-setting :key="componentKey1"></instance-setting>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import InstanceAdd from './InstanceAdd.vue'
import InstanceSetting from './InstanceSetting.vue'

export default {
  name: 'Instance',
  components: {
    InstanceAdd,
    InstanceSetting,
  },
  props: {},
  data() {
    return {
      componentKey: 0,
      componentKey1: 0,

      tab: '',
      tabs: [{ title: 'Tambah Instansi Baru' }, { title: 'Setting' }],
    }
  },
  methods: {
    reRenderComponent(index) {
      if (index === 0) {
        this.componentKey += 1
      } else {
        this.componentKey1 += 1
      }
    },
  },
}
</script>

<style>
</style>
