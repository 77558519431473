<template>
  <div>
    <v-card>
      <DataTablePagination
        :page="page"
        :total-items="totalItems"
        :headers="headers"
        :items="instances"
        :search="search"
        :total-pages.sync="totalPages"
        :is-loading="isLoadingData"
        :filter-instance-type="filterInstanceType"
        title="Instansi"
        subtitle="Halaman untuk menambahkan instansi baru ke dalam aplikasi"
        add-btn="Tambah Data"
        @add-item="showFormAdd"
        @edit-item="showFormEdit"
        @delete-item="confirmDestroy"
        @history-item="showHistory"
        @change-page="paginationHandler"
        @open-dialog="newOrderPacket"
        @instance-spod="instanceSpod"
        @live-search="searchHandler"
      >
      </DataTablePagination>

      <v-dialog
        v-model="addFormDialog"
        persistent
        width="1080"
      >
        <v-card>
          <v-card-title
            v-if="dialog ==='add'"
            class="headline justify-center"
          >
            Tambah Instansi
          </v-card-title>
          <v-card-title
            v-if="dialog ==='edit'"
            class="headline justify-center"
          >
            Edit Instansi
          </v-card-title>
          <div class="pa-4">
            <v-tabs
              v-model="tab"
              show-arrows
              fixed-tabs
            >
              <v-tab
                v-for="(tabItem,index) in tabs"
                :key="tabItem.icon"
                :disabled="!unlockTab && tabItem.title === 'Lihat Paket Buku'"
                @click="reRenderComponent(index)"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tabItem.icon }}
                </v-icon>
                <span>{{ tabItem.title }}</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
              class="pt-6 pb-0"
            >
              <v-tab-item>
                <instance-data
                  :instance-uuid="instance_uuid"
                  :dialog="dialog"
                  @next-tab="nextTabHandler($event)"
                  @close-dialog="handleCloseData"
                  @component-valid="disabledComponent($event)"
                ></instance-data>
              </v-tab-item>
              <v-tab-item disabled>
                <instance-book-packet
                  :key="componentKey1"
                  :instance="instance"
                  :dialog="dialog"
                  @close-dialog="closeHandler"
                  @next-tab="nextTabHandler"
                  @prev-tab="prevTabHandler"
                ></instance-book-packet>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="historyFormDialog"
        persistent
        width="1080"
      >
        <v-card>
          <v-card-title
            class="headline justify-center"
          >
            Riwayat Pembelian
          </v-card-title>
          <v-icon
            style="position:absolute;top:20px;right:20px"
            @click="handleCloseDialog"
          >
            {{ icons.mdiClose }}
          </v-icon>
          <div class="pa-4">
            <instance-history :instance-uuid="instance_uuid"></instance-history>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="secondOrderDialog"
        persistent
        width="1080"
      >
        <v-card>
          <v-card-title
            class="headline justify-center"
          >
            Belanja Paket Buku
          </v-card-title>
          <v-icon
            style="position:absolute;top:20px;right:20px"
            @click="handleCloseDialogOrder(false)"
          >
            {{ icons.mdiClose }}
          </v-icon>
          <div class="pa-4">
            <instance-order
              :instance-uuid="instance_uuid"
              @handle-close="handleCloseDialogOrder($event)"
            ></instance-order>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="openQRCodeDialog"
        persistent
        width="400"
      >
        <v-card>
          <v-card-title
            class="headline justify-center"
          ></v-card-title>
          <v-icon
            style="position:absolute;top:20px;right:20px"
            @click="handleCloseQRCodeDialog"
          >
            {{ icons.mdiClose }}
          </v-icon>
          <div
            v-if="!isLoadingQr"
            class="text-center pa-4"
          >
            <h3>
              {{ qrCode.name }}
            </h3>
            <a
              :href="qrCode.link"
              download
              target="_blank"
            >
              <v-img
                :src="qrCode.link"
                :lazy-src="qrCode.link"
                alt="qrcode"
              ></v-img>
            </a>
          </div>
          <div
            v-else
            class="text-center py-5 px- 5"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
              :width="5"
            ></v-progress-circular>
          </div>
          <div
            v-if="!qrCode.link && !isLoadingQr"
            class="text-center pa-4"
          >
            <v-img src="@/assets/images/logos/qr-not-found.svg"></v-img>
          </div>
        </v-card>
      </v-dialog>
    </v-card>
    <!-- <v-skeleton-loader
      v-else
      v-bind="attrs"
      type="table"
    >
    </v-skeleton-loader> -->
    <ConfirmDialog
      :visible="confirmDeleteDialog"
      header="Hapus Instansi"
      :is-loading-button="isLoadingButton"
      @destroy-button="destroy"
      @close-modal="confirmDeleteDialog = false"
      @close-button="confirmDeleteDialog = false"
    >
      <template v-slot:body>
        <p>Apakah anda yakin ingin menghapus data ini?</p>
        <p>Seluruh data dari instansi ini akan dihapus <b>PERMANENT</b>. Silahkan ketik <b>"Hapus Instansi"</b> untuk validasi penghapusan data instansi.</p>
        <v-text-field
          v-model="validateDelete"
          label="'Hapus Instansi'"
          dense
          type="text"
          outlined
          :rules="[validation.required]"
        ></v-text-field>
      </template>
    </ConfirmDialog>
    <!-- <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Instansi"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog> -->
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import { integerValidator, required } from '@core/utils/validation'
import DataTablePagination from '../components/DataTablePagination.vue'
// eslint-disable-next-line object-curly-newline
import router from '@/router'
import {
  mdiBook, mdiBookMusic, mdiClose, mdiFileVideo, mdiMagnify, mdiMapSearch,
} from '@mdi/js'
import InstanceBookPacket from './InstanceBookPacket.vue'
import InstanceData from './InstanceData.vue'
import InstanceHistory from './InstanceHistory.vue'
import InstanceOrder from './InstanceOrder.vue'

export default {
  name: 'InstanceAdd',
  components: {
    DataTablePagination,
    InstanceData,
    InstanceBookPacket,
    ConfirmDialog,
    InstanceHistory,
    InstanceOrder,
  },
  data() {
    return {
      historyFormDialog: false,
      dialog: '',
      search: '',
      confirmDialog: false,
      confirmCancels: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      isLoadingLibrary: false,
      isLoadingQr: false,
      searchDebounce: '',
      secondOrderDialog: false,
      addFormDialog: false,
      openQRCodeDialog: false,
      editFormDialog: false,
      confirmDeleteDialog: false,
      filterInstanceType: true,
      page: 1,
      totalPages: 1,
      totalItems: 0,
      itemsPerPage: 10,
      validation: {
        required,
        integerValidator,
      },
      validateDelete: null,
      icons: {
        mdiMagnify,
        mdiBook,
        mdiBookMusic,
        mdiFileVideo,
        mdiMapSearch,
        mdiClose,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tanggal Daftar', value: 'created_at' },
        { text: 'Nama Instansi', value: 'name' },
        { text: 'Tipe Instansi', value: 'company_types_uuid.type' },
        { text: 'Aksi', value: 'actions' },
      ],
      tab: '',
      tabs: [{ title: 'Data Instansi' }, { title: 'Lihat Paket Buku' }],
      componentKey: 0,
      componentKey1: 0,
      instances: [],
      instance: {},
      instance_uuid: null,
      componentValid: false,
      serviceInstance: 'instance',
      filterQuery: {
        type: '',
      },
      qrCode: {
        name: null,
        link: null,
        blob: null,
      },
      unlockTab: false,
      cType: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.listInstance(this.filterQuery)
      },
    },
  },
  created() {
    this.listInstance()
  },
  methods: {
    async listInstance(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.serviceInstance, {
        ...params,
        page: this.page,
      }).then(
        ({ data }) => {
          this.instances = data.data.map((type, index) => ({
            ...type,
            index: index + 1,
            created_at: type.user.created_at,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
          this.isLoadingTable = false
          this.isLoadingData = false
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    async getInstance(uuid) {
      this.isLoadingQr = true
      await this.$services.ApiServices.get(this.serviceInstance, uuid).then(
        async ({ data }) => {
          this.qrCode.link = data.data.qr_code
          this.qrCode.name = data.data.name
          this.cType = data.data.company_types_uuid.type
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingQr = false
    },
    confirmDestroy(uuid) {
      this.confirmDeleteDialog = true
      this.instance_uuid = uuid
      this.validateDelete = null
    },

    async destroy() {
      if (this.validateDelete === null || this.validateDelete !== 'Hapus Instansi') {
        this.showSnackbar({
          text: "Please, type 'Hapus Instansi' first !!! ",
          color: 'error',
        })

        return
      }
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.serviceInstance, this.instance_uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Delete Instance Has Successfully',
            color: 'error',
          })
          this.listInstance(this.filterQuery)
        },
        err => console.error(err),
      )
      this.isLoadingButton = false
      this.confirmDeleteDialog = false
    },
    reRenderComponent(index) {
      if (index !== this.tab) this.unlockTab = !this.unlockTab

      this.componentKey1 += 1
    },
    nextTabHandler(data) {
      this.unlockTab = true
      this.componentKey1 += 1
      this.instance = data
      this.tab += 1
    },
    disabledComponent(formValid) {
      this.componentValid = formValid
    },
    prevTabHandler() {
      this.unlockTab = false
      this.tab -= 1
    },
    closeHandler() {
      this.dialog = ''
      this.addFormDialog = false
      this.tab = ''
      this.listInstance(this.filterQuery)
      // this.resetForm()
    },
    handleCloseData() {
      this.addFormDialog = false
      this.instance_uuid = null
      this.dialog = ''
    },
    showFormEdit(uuid) {
      this.dialog = 'edit'
      this.instance_uuid = uuid
      this.addFormDialog = true
    },
    showFormAdd() {
      this.dialog = 'add'
      this.instance_uuid = null
      this.instance = {}
      this.addFormDialog = true
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listInstance(this.filterQuery)
      console.log(this.filterQuery.search)
    },
    async newOrderPacket(uuid) {
      this.instance_uuid = uuid
      this.secondOrderDialog = true
    },
    async instanceSpod(uuid) {
      await this.getInstance(uuid)
      if (this.cType === 'sekolah') {
        this.openQRCodeDialog = true
      } else {
        router.push({ name: 'instance-spod-admin', params: { uuid } })
      }
    },
    async printQRCode(uuid) {
      this.openQRCodeDialog = true
      await this.getInstance(uuid)
    },
    async showHistory(uuid) {
      this.instance_uuid = uuid
      this.historyFormDialog = true
    },
    handleCloseDialog() {
      this.instance_uuid = null
      this.historyFormDialog = false
    },
    handleCloseQRCodeDialog() {
      this.instance_uuid = null
      this.openQRCodeDialog = false
    },
    handleCloseDialogOrder(reFetch) {
      this.instance_uuid = null
      this.secondOrderDialog = false
      if (reFetch) {
        this.listInstance(this.filterQuery)
      }
    },
  },
}
</script>
